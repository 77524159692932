import React from "react";
import { abbrev } from "../util";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { ABBREV_LEN } from "../constants";

const ProductRow = ({ product, onSelect, setShowInfo }) => (
  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    onClick={() => {
      setShowInfo(true);
      onSelect(product);
    }}
  >
    <TableCell>{abbrev(product.category, ABBREV_LEN)}</TableCell>
    <TableCell>{abbrev(product.brand, ABBREV_LEN)}</TableCell>
    <TableCell>{product.product}</TableCell>
  </TableRow>
);

export default ProductRow;
