import React from "react";

const ProductFilter = ({ filterstr, setFilter }) => {
  return (
    <input
      value={filterstr}
      onChange={(evt) => setFilter(evt.target.value)}
      placeholder="Enter Brand &/or category ..."
    />
  );
};

export default ProductFilter;
