import ProductRow from "./ProductRow";
import { filterOnQuery } from "../util";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const ProductTable = ({ data, filterstr, setSelectedItem, setShowInfo }) => {
  const filteredData = data.filter(
    (product) => filterstr === "" || filterOnQuery(filterstr, product) // show all if no filter
  );
  const numItems = filteredData.length;
  let Categories = new Set();
  let Brands = new Set();
  for (let i = 0; i < numItems; i++) {
    Categories.add(filteredData[i].category);
    Brands.add(filteredData[i].brand);
  }
  // const numCategories = new Set(filteredData.map((item) => item.category)).size;
  // const numBrands = new Set(filteredData.map((item) => item.brand)).size;
  const infoString = `...filtered to ${Categories.size} categories across ${Brands.size} brands, ${numItems} total items :`;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>{infoString}</TableRow>
          <TableRow>
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">Brand&nbsp;</TableCell>
            <TableCell align="left">Product&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.slice(0, 10).map((item) => (
            <ProductRow
              product={item}
              key={item.index}
              onSelect={(item) => setSelectedItem(item)}
              setShowInfo={setShowInfo}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;
