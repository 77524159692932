const abbrev = (str, len) => {
  if (!str) {
    return "";
  }
  if (len < str.length) {
    return str.slice(0, len) + "...";
  } else {
    return str;
  }
};

/**
 * @param {*} spacedstr
 * @returns the words that make up the spacedstr, with all white spaces removed
 */
const getWords = (spacedstr) => {
  let components = spacedstr.split(" ");
  let words = [];
  for (let i = 0; i < components.length; i++) {
    if (components[i] !== "") {
      words.push(components[i]);
    }
  }
  return words;
};

/**
 * @param {*} spacedstr
 * @param {*} product
 * @returns True if substrs of spacedstr matches both the brand and category of the product record
 */
const filterOnQuery = (spacedstr, product) => {
  let words = getWords(spacedstr);
  if (product.brand && product.product) {
    // (word[i] is a substring of the brand or product), and (word[j] is a substring of the brand or product)
    let found = true;
    for (let i = 0; i < words.length; i++) {
      found =
        found &&
        (product.category.toLowerCase().includes(words[i].toLowerCase()) ||
          product.brand.toLowerCase().includes(words[i].toLowerCase()) ||
          product.product.toLowerCase().includes(words[i].toLowerCase()));
      if (!found) {
        return false;
      }
    }
    return found;
  }
  return false; // dont show if no brand or product
};

export { abbrev, getWords, filterOnQuery };
