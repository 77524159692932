import "./App.css";
import React, { useEffect, useState } from "react";

import ItemInfo from "./components/ItemInfo";
import ProductFilter from "./components/ProductFilter";
import ProductTable from "./components/ProductTable";
import Header from "./components/Header";
import { Container, Stack } from "@mui/material";

const JSON_DATA_URI = "data/produkter_202103.json";

function App() {
  const [filterstr, setFilter] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState([]);
  const [showInfo, setShowInfo] = useState(true);

  useEffect(() => {
    fetch(JSON_DATA_URI)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, []);
  return (
    <Container className="App">
      <Header />
      <Container>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          {selectedItem && (
            <ItemInfo
              showInfo={showInfo}
              setShowInfo={setShowInfo}
              item={selectedItem}
            />
          )}
          <Container>
            <ProductFilter filterstr={filterstr} setFilter={setFilter} />
            <ProductTable
              data={data}
              filterstr={filterstr}
              setSelectedItem={setSelectedItem}
              setShowInfo={setShowInfo}
            />
          </Container>
        </Stack>
      </Container>
    </Container>
  );
}

export default App;
