const Header = () => {
  return (
    <div>
      <h1 className="title" text-align="left">Product Search</h1>
      <p className="insxns" color="blue"> type in partial/full category &/or brand &/or product with spaces in between to search for ...</p>
    </div>

  );
};

export default Header;
