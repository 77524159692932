import React from "react";
import Button from "@mui/material/Button";

const ItemInfo = ({ item, showInfo, setShowInfo }) => {
  return (
    showInfo && (
      <div>
        <Button variant="outlined" onClick={() => setShowInfo(false)}>
          Clear
        </Button>
        <h3>Item Info</h3>
        <table className="itemInfoTable">
          {Object.keys(item).map(
            (key) =>
              key !== "index" && (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{item[key]}</td>
                </tr>
              )
          )}
        </table>
      </div>
    )
  );
};

export default ItemInfo;
